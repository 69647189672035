html,
body {
  padding: 0;
  margin: 0;
  font-family: "Poppins", "Noto Sans", sans-serif;
  font-size: 16px;
  line-height: 1.5;
}

body.bodyScrollDisabled {
  overflow: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

input,
textarea,
select,
button {
  font-family: "Poppins", "Noto Sans", sans-serif;
  padding: 0;
  outline: none;
}

.custom_fc_frame {
  bottom: 70px !important;
}

.grecaptcha-badge {
  visibility: hidden;
}
